import {
  CODE_CHRONO_DOMICILE,
  CODE_CHRONO_PRECISE,
  CODE_EXTRA_MAIN_TAXON,
  CODE_MEAL_MAIN_TAXON,
  DEFAULT_SHIPMENT_TIME,
} from "@middleware/constants";
import {
  BundleCodePromotionMap,
  DiscountType,
  EPaymentState,
  EStateOrder,
  IBilanQuestion,
  IBilanResponse,
  IBillingAddress,
  ICustomer,
  IMethodValue,
  IOrder,
  IOrderAddress,
  IOrderItem,
  IPaymentMethodValue,
  IProduct,
  ISchedule,
  IShippingAddress,
  IShippingMethodsResponse,
  WindowWithDataLayer,
} from "@middleware/types";
import { Content } from "@prismicio/client";
import { client as env } from "@config/env/client";
import {
  getRemainingDeferral,
  getShippingSlotsValues,
  hasCustomBundle,
} from "./cart";

export function isEmpty(value: object | string | undefined | null) {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
}

export function getCountryName(countryCode: string): string {
  const regionNames = new Intl.DisplayNames(["fr"], { type: "region" });

  return regionNames.of(countryCode) ?? "";
}

export function limitText(string: string | null, limit = 0) {
  if (string === null) return "";

  return string.substring(0, limit) + (string.length > limit ? "..." : "");
}

export const getFormattedPrice = (price = 0): string => {
  return (price / 100)
    .toLocaleString("fr-FR", {
      style: "currency",
      currency: "EUR",
    })
    .replace(/\s/g, "")
    .replace(",00", "");
};

export const getFormattedDiscountAmount = (
  discountAmount: number,
  discountType: DiscountType
) => {
  if (
    discountType === DiscountType.UNIT_PERCENTAGE ||
    discountType === DiscountType.ORDER_PERCENTAGE
  )
    return `${discountAmount * 100}%`;

  return getFormattedPrice(discountAmount);
};

export const getFormattePromotionBundlePrice = (
  bundlePrice: number,
  discountAmount: number,
  discountType: DiscountType
) => {
  if (
    discountType === DiscountType.UNIT_PERCENTAGE ||
    discountType === DiscountType.ORDER_PERCENTAGE
  )
    return getFormattedPrice(
      bundlePrice - (discountAmount * 100 * bundlePrice) / 100
    );

  return getFormattedPrice(bundlePrice - discountAmount);
};
export const getPromotionBundlePrice = (
  bundlePrice: number,
  discountAmount: number,
  discountType: DiscountType
) => {
  if (
    discountType === DiscountType.UNIT_PERCENTAGE ||
    discountType === DiscountType.ORDER_PERCENTAGE
  )
    return bundlePrice - (discountAmount * 100 * bundlePrice) / 100;

  return bundlePrice - discountAmount;
};

export const getNumericDate = (date: string) => {
  const currentDate = generateDate(date);
  const year = currentDate.getFullYear();
  let month = `${currentDate.getMonth() + 1}`;
  let day = `${currentDate.getDate()}`;

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join("-");
};

export const getAverageMealPrice = (
  price: number,
  mealsQuantity: number
): string => {
  return getFormattedPrice(Math.ceil(price / mealsQuantity));
};

export const generateDate = (date?: string | null): Date => {
  if (date === undefined || date === null || date === "") return new Date();
  if (date.length > 19) return new Date(date);

  const isFullDate = date.length > 10;
  const year = parseInt(date.substring(0, 4));
  const month = parseInt(date.substring(5, 7)) - 1;
  const day = parseInt(date.substring(8, 10));
  const hour = isFullDate ? parseInt(date.substring(11, 13)) : 0;
  const minutes = isFullDate ? parseInt(date.substring(14, 16)) : 0;
  const seconds = isFullDate ? parseInt(date.substring(17, 19)) : 0;

  return new Date(year, month, day, hour, minutes, seconds);
};

export const getFormattedDate = (
  date: string,
  options: Record<string, string> = {
    weekday: "long",
    month: "long",
    day: "numeric",
  }
): string => {
  return generateDate(date).toLocaleDateString("fr-FR", options);
};

export const getBasicFormattedDate = (date: Date): string => {
  const d = new Date(date),
    year = d.getFullYear().toString();
  let month = (d.getMonth() + 1).toString(),
    day = d.getDate().toString();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const getNextMenuDate = (): string => {
  const now = generateDate();
  const day = now.getDay();
  const hour = now.getHours();
  const isEndOfWeek = day === 0 || day > 4 || (day === 4 && hour >= 12);
  if (isEndOfWeek) now.setDate(now.getDate() + 5);
  const menuDate = isEndOfWeek ? getBasicFormattedDate(now) : "current";

  return menuDate;
};

export const getFormattedTime = (date: string) => {
  const hour = generateDate(date).getHours();

  return `${hour}h`;
};

export const getContactEmail = () => {
  return env.NEXT_PUBLIC_CONTACT_EMAIL;
};

export const getContactNumber = () => {
  return env.NEXT_PUBLIC_CONTACT_NUMBER;
};

export const getNestedProperty = <T>(
  objectIndex: string,
  object: T | undefined
): T | undefined => {
  return objectIndex
    .replace(/\[([^\]]+)]/g, ".$1")
    .split(".")
    .reduce((currentObject, index) => {
      if (currentObject === undefined || currentObject === null)
        return undefined;

      return currentObject[index as keyof typeof currentObject] as T;
    }, object);
};

export const computeProvinceCode = (
  address: IBillingAddress | IShippingAddress
) => {
  return address.countryCode + "-" + address.postcode.slice(0, 2);
};

export const refactorAddress = (address: IOrderAddress) => {
  const orderAddress = {
    shippingAddress: address.shippingAddress,
    billingAddress:
      address.useDifferentBillingAddress === true
        ? address.billingAddress
        : address.shippingAddress,
  };
  orderAddress.shippingAddress.provinceCode = computeProvinceCode(
    orderAddress.shippingAddress
  );
  orderAddress.billingAddress.provinceCode = computeProvinceCode(
    orderAddress.billingAddress
  );

  return orderAddress;
};

export const serializeBilanQuestions = (
  questions: Content.BilanQuestionsDocument
): IBilanQuestion[] => {
  return questions.data.slices.map((slice) => ({
    label: slice.primary.question_label ?? "",
    answers: slice.items.map((item) => ({
      answer: item.answer_label ?? "",
      score: item.answer_score ?? "",
    })),
  }));
};

export const serializeBilanResponses = (
  responses: Content.BilanQuestionsDocument
): IBilanResponse[] => {
  const _responses: IBilanResponse[] = [];
  responses.data.slices1.map((slice) => {
    slice.items.map((item) => {
      _responses.push({
        scoreThreshold: item.scoreThreshold ?? 0,
        text: item.text,
      });

      return true;
    });

    return true;
  });

  return _responses;
};

export const getGoogleAddressPostcode = (
  place: google.maps.places.PlaceResult
): string => {
  const postalCode = place.address_components?.find(
    (component) => component.types[0] === "postal_code"
  );

  return postalCode ? postalCode.long_name : "";
};

export const getGoogleAddressCity = (
  place: google.maps.places.PlaceResult
): string => {
  const city = place.address_components?.find(
    (component) => component.types[0] === "locality"
  );

  return city ? city.long_name : "";
};

export const getOrderExtras = (order: IOrder) => {
  const extrasItems = order.items.filter(
    (item) => item.productTypeTaxon === CODE_EXTRA_MAIN_TAXON
  );
  const extrasCount = extrasItems.reduce(
    (accum, item) => (accum += item.quantity * (item.productPackaging ?? 1)),
    0
  );
  const extrasTotal = extrasItems.reduce(
    (accum, item) => (accum += item.total),
    0
  );

  const isCustomBundle = hasCustomBundle(order);

  return { extrasItems, extrasCount, extrasTotal, isCustomBundle };
};

export const getOrderOffered = (order: IOrder) => {
  const offeredItems = order.displayedOfferedItems;
  const offeredCount =
    offeredItems.length > 0
      ? offeredItems.reduce(
          (accum, item) =>
            (accum += item.quantity * (item.productPackaging ?? 1)),
          0
        )
      : 0;

  return { offeredItems, offeredCount };
};

export const getOrderMeals = (order: IOrder | undefined) => {
  if (order === undefined) return { mealsItems: [], mealsCount: 0 };

  const mealsItems = order.items.filter(
    (item) => item.productTypeTaxon === CODE_MEAL_MAIN_TAXON
  );
  const mealsCount = mealsItems.reduce(
    (accum, item) => (accum += item.quantity),
    0
  );

  return { mealsItems, mealsCount };
};

export const addHoursToDate = (date: string, hours: number) => {
  const newDate = generateDate(date);
  newDate.setTime(newDate.getTime() + hours * 60 * 60 * 1000);

  return newDate;
};

export const decreaseHoursToDate = (date: string, hours: number) => {
  const newDate = generateDate(date);
  newDate.setTime(newDate.getTime() - hours * 60 * 60 * 1000);

  return newDate;
};

export const getPreciseSlot = (date: string, showMinute = false) => {
  const minutesValues = showMinute
    ? `${generateDate(date).getMinutes().toString().padStart(2, "0")}`
    : "";
  const startHour = generateDate(date).getHours();
  const endHour = addHoursToDate(date, 2).getHours();

  return `${startHour}h${minutesValues} - ${endHour}h${minutesValues}`;
};

export const getShipmentDeadline = (date: string) => {
  return decreaseHoursToDate(date, 1).toString();
};

export const getShipmentDeadlineTime = (date: string) => {
  return decreaseHoursToDate(date, 1).getHours();
};

export const getShipmentAddress = (
  shippingAddress: IShippingAddress
): string => {
  return (
    shippingAddress.street +
    ", " +
    shippingAddress.postcode +
    ", " +
    shippingAddress.city +
    ", " +
    getCountryName(shippingAddress.countryCode)
  );
};

export const getFormatPhoneNumber = (phoneNumber: string) => {
  const numbers = ("" + phoneNumber).replace(/\D/g, "");
  const match = numbers.match(/^(\d{2})(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})$/);
  if (match) {
    const countryCode = "+" + match[1];

    return [countryCode, match[2], match[3], match[4], match[5], match[6]].join(
      " "
    );
  }

  return phoneNumber;
};

export const getItemSku = (item: IOrderItem) => {
  return item.productCode;
};

export const getNumberFromString = (text: string) => {
  const matches = text.match(/(\d+)/);

  return matches ? parseInt(matches[0]) : 0;
};

export const getDataLayerFixPrice = (cart: IOrder) => {
  const { mealsCount } = getOrderMeals(cart);

  switch (mealsCount) {
    case 8:
      return 336;
    case 10:
      return 365;
    case 12:
      return 421;
    case 14:
      return 410;
    case 16:
      return 468;
    case 18:
      return 533;
    default:
      return 422;
  }
};

declare const window: WindowWithDataLayer;
export const pushDataLayerSuccess = (cart: IOrder) => {
  const transactionProducts = cart.items.map((item) => ({
    item_id: getItemSku(item),
    item_name: item.productName,
    item_category: item.productTypeTaxon,
    price: item.unitPrice,
    quantity: item.quantity,
  }));

  const bundlePrice = getDataLayerFixPrice(cart);
  window.dataLayer = window.dataLayer.length > 0 ? window.dataLayer : [];
  window.dataLayer.push({
    event: "purchase",
    ecommerce: {
      currency: "EUR",
      transaction_id: cart.number,
      value: bundlePrice,
      coupon: cart.promotionCoupon?.code ?? "",
      shipping: cart.shippingTotal / 100,
      tax: cart.taxTotal / 100,
      items: transactionProducts,
    },
  });
};

export const pushUserDataLayer = (customer: ICustomer) => {
  window.dataLayer = window.dataLayer.length > 0 ? window.dataLayer : [];
  const hasOrders = customer.paidOrdersCount > 0;

  window.dataLayer.push({
    event: "account_connexion",
    user_id: customer.id,
    crm_id: customer.id,
    orders: customer.paidOrdersCount,
    delai_first_order: hasOrders ? getDiffDate(customer.firstOrderDate) : "",
  });
};

export const getDiffDate = (date: string) => {
  const nowDate = generateDate();
  const dateDiff = generateDate(date);
  const diffTime = nowDate.getTime() - dateDiff.getTime();
  const diffDays = diffTime / (1000 * 3600 * 24);

  return Math.floor(diffDays);
};

export const getMondayOfDate = (date: string) => {
  const currentDate = generateDate(date);
  const day = currentDate.getDay();
  const currentMonday = generateDate(date);
  if (currentDate.getDay() === 0) {
    currentMonday.setDate(currentDate.getDate() - 6);
  } else {
    currentMonday.setDate(currentDate.getDate() - (day - 1));
  }

  return currentMonday;
};

export const getSundayOfDate = (date: string) => {
  const currentDate = generateDate(date);
  const day = currentDate.getDay();
  const currentSunday = generateDate(date);
  if (currentDate.getDay() === 0) {
    currentSunday.setDate(currentDate.getDate());
  } else {
    currentSunday.setDate(currentDate.getDate() + (7 - day));
  }

  return currentSunday;
};

export const getOrderDetails = (order: IOrder, customer?: ICustomer) => {
  const remainingDeferral = getRemainingDeferral(order);
  const isPaidShipment = order.paymentState === EPaymentState.PAID;
  const isPastShipment = order.state === EStateOrder.ORDER_STATE_FULFILLED;
  const skippedShipment = order.state === EStateOrder.ORDER_STATE_SKIPPED;
  const isCanceledShipment = order.state === EStateOrder.ORDER_STATE_CANCELED;
  const shipmentDeadline = generateDate(order.modificationDeadline);
  const canReactivateShipment = shipmentDeadline > generateDate();
  const canSkipShipment =
    shipmentDeadline > generateDate() &&
    (remainingDeferral > 0 || customer?.engaged === false);
  const disabledShipment =
    skippedShipment ||
    shipmentDeadline < generateDate() ||
    isPaidShipment ||
    isCanceledShipment;
  const paymentDate = isPaidShipment
    ? (order.payments[0].collectionDate as string)
    : order.expectedPaymentDate;

  return {
    isPaidShipment,
    isPastShipment,
    skippedShipment,
    isCanceledShipment,
    shipmentDeadline,
    disabledShipment,
    paymentDate,
    canReactivateShipment,
    canSkipShipment,
  };
};

export const getDefaultShipmentMode = (
  methodsValues: IMethodValue[],
  order?: IOrder
) => {
  if (order !== undefined) return order.shipments[0].method.code;

  return methodsValues.length > 0 ? methodsValues[0].value : "";
};

export const hasDeliverySlots = (
  methods: IShippingMethodsResponse | undefined
) => {
  if (methods?.schedule === undefined) return false;

  return Object.keys(methods.schedule).length > 0;
};

export const getDefaultShippingSlot = (
  shippingMethods: ISchedule | undefined,
  shippingMode: string,
  order?: IOrder
) => {
  if (shippingMode !== CODE_CHRONO_PRECISE) return undefined;
  if (shippingMethods === undefined) return undefined;
  if (order === undefined) return undefined;

  const defaultShipmentDay = getNumericDate(order.chosenDeliveryDate);
  const deliverySlots =
    getShippingSlotsValues(shippingMethods, defaultShipmentDay) ?? [];

  return deliverySlots.find(
    (slot) => slot.value.date === order.chosenDeliveryDate
  )?.value;
};

export const getDefaultShipmentDay = (
  daysValues: IMethodValue[],
  order?: IOrder
) => {
  if (order !== undefined) return getNumericDate(order.chosenDeliveryDate);

  return daysValues.length > 0 ? daysValues[0].value : "";
};

export const getShipmentDateLabel = (order: IOrder) => {
  return getFormattedDate(order.chosenDeliveryDate, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const getShipmentModeLabel = (
  order: IOrder,
  domicilLabel: string,
  preciseLabel: string
) => {
  const shipmentMethod = order.shipments[0].method.code;

  return shipmentMethod === CODE_CHRONO_DOMICILE
    ? domicilLabel + ", " + DEFAULT_SHIPMENT_TIME
    : preciseLabel + ", " + getPreciseSlot(order.chosenDeliveryDate);
};

export const getDefaultPaymentMethod = (
  methodsValues: IPaymentMethodValue[],
  order?: IOrder
) => {
  if (order !== undefined) return order.payments[0].method.code;

  return methodsValues.length > 0 ? methodsValues[0].method : "";
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const scrollToSection = (idSection: string) => {
  const element = document.getElementById(idSection) as HTMLElement;
  if (typeof element.scrollIntoView === "function") {
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
};

export const getMatchingPromotion = (
  bundle: IProduct,
  bundlePromotions: BundleCodePromotionMap
) => {
  if (bundle.code in bundlePromotions) {
    return bundlePromotions[bundle.code];
  }

  return undefined;
};

export const getNextMondayOfDate = (date: string) => {
  const numericDate = getNumericDate(date.toString());

  const currentDate = generateDate(numericDate);
  const day = currentDate.getDay();
  const currentMonday = generateDate(numericDate);
  if (day === 0) {
    currentMonday.setDate(currentDate.getDate() + 1);
  } else {
    currentMonday.setDate(currentDate.getDate() + (8 - day));
  }

  return getNumericDate(currentMonday.toString());
};

export const isPastSkippedOrder = (order: IOrder) => {
  const nextMonday = getNextMondayOfDate(order.chosenDeliveryDate);

  return (
    order.state === EStateOrder.ORDER_STATE_SKIPPED &&
    generateDate() > generateDate(nextMonday)
  );
};
